let info = {
  name: "Mernante Tullaga",
  logo_name: "Mernz",
  flat_picture: require("./src/assets/avatar.png"),
  config: {
    use_cookies: true,
    navbar: {
      blur: false
    }
  },
  description:
    "I'm Mernante, hailing from Davao City, Philippines, and I'm a dedicated Full-stack Web Developer with a strong focus on web technologies including Javascript, CSS3, and HTML5. I have a fervent drive for creating projects that deliver regular value and have been honing my skills in this field for approximately five years. <br>Presently, my primary focus revolves around crafting JavaScript applications using Node.js, coupled with expertise in the popular front-end technologies of Vue.js and the Quasar framework. Additionally, I possess a profound understanding of SEO implementation, having previously assisted my employers in optimizing their websites for top-ranking performance, enhancing web application speed, and streamlining operational efficiency.",
  links: {
    linkedin: "https://www.linkedin.com/in/mernante-tullaga-39527913a/",
    github: "https://github.com/mtullaga",
    facebook: "https://www.facebook.com/mernante.tullaga/",
    resume:
      "https://drive.google.com/file/d/1Tp-hetv3nAgc8u0CV_gp74lz_nVEvaVq/view?usp=sharing"
  },
  education: [
    {
      name: "University of Mindanao",
      place: "Davao City, Philippines",
      date: "October 2018",
      degree: "Bachelor of Science in Information Technology",
      gpa: "",
      skills: [
        "Skills:",
        "Design, conduct and interpret research and statistical analysis. ",
        "Apply an evidence-based approach to problems.",
        "Communicate findings using models, charts and graphs.",
        "Communicate research findings using clear, accurate, concise writing.",
      ]
    },
    {
      name: "Zillovia National High School",
      place: "Agusan del Sur, Philippines",
      date: "April 2012",
      degree: "",
      gpa: "",
      description: "",
      skills: []
    }
  ],
  experience: [
    {
      name: "Online Fulltime",
      place: "Flutterlytics LLC, Dubai, UAE",
      date: "April. 2022 - Present",
      position: "Landing Page Optimizer",
      description:
        "Implementing SEO and maintain the highest scale traffic to their landing pages. Designing user interactions on web pages. Ensuring responsiveness of applications.",
        skills: [
          "HTML5",
          "CSS3",
          "JavaScript",
          "JQuery"
        ]
    },
    {
      name: "Freelance",
      place: "Valdani Thread Inc., Alberta, Canada",
      date: "Nov. 2022 - Sep. 2023",
      position: "Full Stack Developer",
      description:
        "Developing Front-end website architecture. Designing user interactions on web pages. Ensuring responsiveness of applications. Seeing through a project from conception to finished product. Staying abreast of developments in web applications and programming languages.",
        skills: [
          "HTML5",
          "CSS3",
          "JavaScript",
          "Quasar",
          "VueJS 3.0",
          "Directus"
        ]
    },
    {
      name: "Online - Fulltime",
      place: "Cleverativy, Padova, Italy",
      date: "June. 2021 - April 2022",
      position: "Full-stack Developer",
      description:
        "Developing tailor-made software solutions to help manufacturers improve their workflow and increase their revenue with Cutting-Edge Business Software Solutions and Mobile Apps.",
        skills: [
          "HTML5",
          "CSS3",
          "JavaScript",
          "Quasar",
          "RxDB",
          "ApostropheCMS"
        ]
    },

  ],
  skills: [
    {
      title: "Languages",
      info: [
        "Javascript",
        "Typescript",
        "CSS3",
        "SCSS",
        "HTML5"
      ],
      icon: "fa fa-code"
    },
    {
      title: "Front-end & Back-end Frameworks",
      info: [
        "Quasar, ApostropheCMS, Directus, ExpressJS, Apache Cordova"
      ],
      icon: "fa fa-cubes"
    },
    {
      title: "Web Technologies",
      info: ["Vuejs", "Nodejs", "JQuery", "Expressjs"],
      icon: "fas fa-laptop-code"
    },
    {
      title: "Databases",
      info: ["MongoDB", "MySQL", "PostgreSQL", "Firestore", "SQLite"],
      icon: "fa fa-database"
    },
    {
      title: "Operating Systems & Tools",
      info: [
        "Linux",
        "Windows",
        "Android",
        "Heroku",
        "Firebase",
        "DigitalOcean",
        "Cloudflare"
      ],
      icon: "fas fa-tools"
    },
    {
      title: "Graphics & Design",
      info: ["Illustrator", "Photoshop", "Filmora", "Canva", "Powerdirector"],
      icon: "fa fa-pencil-square-o"
    }
  ],



  portfolio: [

    // {
    //   name: "Mern-Cred Credential Storage",
    //   pictures: [

    //     {
    //       img: require("./src/assets/portfolio/mern1.png")
    //     },
    //     {
    //       img: require("./src/assets/portfolio/mern2.png")
    //     },
    //     {
    //       img: require("./src/assets/portfolio/mern3.png")
    //     },

    //   ],
    //   technologies: ["JavaScript", "HTML5", "Firebase", "Quasar", "VueJS", "Firestore"],
    //   category: "Web App",
    //   // github: "https://github.com/Natain/todo-application",
    //   date: "",
    //   visit: "https://mern-cred.web.app/",
    //   description:
    //     "Fully encrypted storage for credentials."
    // },
    {
      name: "Valdani Inc. Store Website",
      pictures: [
        {
          img: require("./src/assets/portfolio/valdani1.png")
        },
        {
          img: require("./src/assets/portfolio/valdani2.png")
        },
        {
          img: require("./src/assets/portfolio/valdani3.png")
        },
        {
          img: require("./src/assets/portfolio/valdani4.png")
        }
      ],
      technologies: ["CSS", "JavaScript","Vue.js", "HTML5", "Directus", "DigitalOcean", "Quasar"],
      category: "Web App",
      // github: "https://github.com/Natain/grocery-listing-react",
      date: "",
      visit: "https://www.valdani.com/",
      description:  "Valdani website and online store. Visitors can view products and can add to cart.",
    },

    {
      name: "Almanon Trading Inventory System",
      pictures: [
        {
          img: require("./src/assets/portfolio/almanon2.png")
        },
        {
          img: require("./src/assets/portfolio/almanon1.png")
        },
        {
          img: require("./src/assets/portfolio/almanon3.png")
        },
        {
          img: require("./src/assets/portfolio/almanon4.png")
        }
      ],
      technologies: ["Quasar", "Herokuapp", "Firebase", "EmailJS"],
      category: "Web App",
      // github: "https://github.com/Natain/gitscores",
      date: "",
      visit: "https://almanon-trading.web.app/",
      description:
        "Inventory Management system tools for Almanon Motor Parts Trading."
    },
    {
      name: "Flutterlytics LLC Website",
      pictures: [
        {
          img: require("./src/assets/portfolio/flutter1.png")
        },
        {
          img: require("./src/assets/portfolio/flutter2.png")
        },
        {
          img: require("./src/assets/portfolio/flutter3.png")
        }
      ],
      technologies: ["JavaScript", "CSS", "VueJS", "HTML5"],
      category: "Web App",
      // github: "https://github.com/Natain/gitscores",
      date: "",
      visit: "https://flutterlytics.com/",
      description:
        "Flutterlytics is a digital, premium marketing and consulting solution, supported by our data, analytics, and optimizations."
    },
    {
      name: "Aspen Center for Physics Website",
      pictures: [
        {
          img: require("./src/assets/portfolio/aspen1.png")
        },
        {
          img: require("./src/assets/portfolio/aspen2.png")
        },
        {
          img: require("./src/assets/portfolio/aspen3.png")
        }
      ],
      technologies: ["VueJS", "ApostropheCMS", "HTML5"],
      category: "Web App",
      // github: "https://github.com/Natain/gitscores",
      date: "",
      visit: "https://aspen-website.srvnve02.cleverativity.com/",
      description:
        "A Website for Aspen Center for Physics with Events Management System"
    }
  ],

  portfolio_design: [

    {
      name: "Todo-application",
      pictures: [

        {
          img: require("./src/assets/portfolio/aspen3.png")
        },
        {
          img: require("./src/assets/portfolio/aspen3.png")
        },

      ],
      technologies: ["JavaScript", "HTML5", "Netlify"],
      category: "web todo list  app ",
      github: "https://github.com/Natain/todo-application",
      visit: "https://natainbogdan.netlify.app/",
      description:
        "Todo Application was made to help me with the learning schedule and it seams was doing a great job this app helping me to organize all the daily tasks."
    },



    {
      name: "Grocery-listing-react",
      pictures: [
        {
          img: require("./src/assets/portfolio/aspen3.png")
        },
        {
          img: require("./src/assets/portfolio/aspen3.png")
        }
      ],
      technologies: ["CSS", "JavaScript","React.js", "HTML5",],
      category: "Web App",
      github: "https://github.com/Natain/grocery-listing-react",
      date: "",
      visit: "https://grocery-listing-react.netlify.app/",
      description:  "Grocery-listing-react it's a simple app that can help you doing shoppings.",
    },
    {
      name: "Gitscores",
      pictures: [
        {
          img: require("./src/assets/portfolio/aspen3.png")
        },
        {
          img: require("./src/assets/portfolio/aspen3.png")
        },
        {
          img: require("./src/assets/portfolio/aspen3.png")
        },

      ],
      technologies: ["JavaScript", "CSS", "EJS", "Herokuapp"],
      category: "Scoring app for Github",
      github: "https://github.com/Natain/gitscores",
      date: "Aug, 2021 - Sep, 2021",
      visit: "https://gitscores.herokuapp.com/",
      description:
        "Gitscores v1.0.2 was updated from the orginal with more tools that let you personalize the app on your desingn."
    }
  ],


  //  recommendations: [
  //   {
  //     title:
  //       "In his internship, Bogdan Natain has demonstrated excellent learning ability, and with a dedicated, task oriented approach, he was able to complete his assignment ahead of time.",
  //     author: "Timothy Gray",
  //     position: "CTO",
  //     company: "Startup Norway As",
  //     location: " Oslo, Norway"
  //   },
  //   {
  //     title:
  //       "I feel his budding leadership abilities will become even more effective in a diverse and challenging environment.",
  //     author: "Hansley Jacob",
  //     position: "Project Manager",
  //     company: "Startup Norway As",
  //     location: "Oslo, Norway"
  //   }
  // ]
};

export default info;



