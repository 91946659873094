var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('nav',{staticClass:"navbar navbar-expand-lg navbar-light fixed-top p-st",class:{
      'bg-light': !_vm.nightMode,
      'navbar-blur': _vm.navbarConfig.blur,
      'bg-dark2': _vm.nightMode,
    }},[_c('div',{staticClass:"container"},[_c('a',{staticClass:"navbar-brand",attrs:{"href":"/"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('scroll', 'home')}}},[_c('Logo',{attrs:{"nightMode":_vm.nightMode}})],1),_vm._m(0),_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarSupportedContent"}},[_c('ul',{staticClass:"navbar-nav ml-auto"},[_c('li',{staticClass:"nav-item mx-2"},[_c('a',{staticClass:"nav-link",class:{ 'text-light': _vm.nightMode },attrs:{"href":"/about"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('scroll', 'about')}}},[_vm._v("ABOUT")])]),_c('li',{staticClass:"nav-item mx-2"},[_c('a',{staticClass:"nav-link",class:{ 'text-light': _vm.nightMode },attrs:{"href":"/skills"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('scroll', 'skills')}}},[_vm._v("SKILLS")])]),_c('li',{staticClass:"nav-item mx-2"},[_c('a',{staticClass:"nav-link",class:{ 'text-light': _vm.nightMode },attrs:{"href":"/portfolio"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('scroll', 'portfolio')}}},[_vm._v("PORTFOLIO")])]),_c('li',{staticClass:"nav-item mx-2"},[_c('a',{staticClass:"nav-link",class:{ 'text-light': _vm.nightMode },attrs:{"href":"/contact"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('scroll', 'contact')}}},[_vm._v("CONTACT")])]),_c('li',{staticClass:"nav-item ml-2"},[_c('a',{staticClass:"nav-link",class:{ 'text-light': _vm.nightMode },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.switchMode.apply(null, arguments)}}},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.nightMode ? 'Light Mode' : 'Night Mode'),expression:"nightMode ? 'Light Mode' : 'Night Mode'",modifiers:{"bottom":true}}],class:{
                  'fas fa-moon': _vm.nightMode,
                  'far fa-moon': !_vm.nightMode,
                }})])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbarSupportedContent","aria-controls":"navbarSupportedContent","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticStyle:{"color":"gray","font-size":"23px"}},[_c('i',{staticClass:"fas fa-bars"})])])
}]

export { render, staticRenderFns }